import { default as contactSZankz4qRuMeta } from "/application/pages/contact.vue?macro=true";
import { default as forgotNYVEfupgVwMeta } from "/application/pages/forgot.vue?macro=true";
import { default as google_45guideKcM7pKHv1hMeta } from "/application/pages/google-guide.vue?macro=true";
import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as inviteujB2l87jUlMeta } from "/application/pages/invite.vue?macro=true";
import { default as indexPzx8h2pxPfMeta } from "/application/pages/join/index.vue?macro=true";
import { default as infoIEJNZx1Ni1Meta } from "/application/pages/join/info.vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as profilesAwopaWV8mMeta } from "/application/pages/mypage/profile.vue?macro=true";
import { default as securityC3ZG62rnXXMeta } from "/application/pages/mypage/security.vue?macro=true";
import { default as passwordkrcfRUa14kMeta } from "/application/pages/reset/password.vue?macro=true";
import { default as resetVnlIHvLZrkMeta } from "/application/pages/reset.vue?macro=true";
import { default as activity_32copypmYcgM6EJ0Meta } from "/application/pages/room/[roomId]/activity copy.vue?macro=true";
import { default as activityw3kA9LDYiUMeta } from "/application/pages/room/[roomId]/activity.vue?macro=true";
import { default as analyticsc3VpecLb7lMeta } from "/application/pages/room/[roomId]/analytics.vue?macro=true";
import { default as formSlb1H5MPKDMeta } from "/application/pages/room/[roomId]/feedback/form.vue?macro=true";
import { default as formXLIMKI4ptUMeta } from "/application/pages/room/[roomId]/form.vue?macro=true";
import { default as index80ZMTecpudMeta } from "/application/pages/room/[roomId]/index.vue?macro=true";
import { default as indexvvGVZ6JrwbMeta } from "/application/pages/room/[roomId]/member/index.vue?macro=true";
import { default as post7Uq0CIn8ByMeta } from "/application/pages/room/[roomId]/member/post.vue?macro=true";
import { default as ownPPwPMZbZNpMeta } from "/application/pages/room/[roomId]/own.vue?macro=true";
import { default as formSfqgg7fQHqMeta } from "/application/pages/room/[roomId]/post/form.vue?macro=true";
import { default as formla8DieUSWPMeta } from "/application/pages/room/form.vue?macro=true";
import { default as indexNXzKTGUbVGMeta } from "/application/pages/room/index.vue?macro=true";
import { default as my_45postsPPALfnHMtVMeta } from "/application/pages/room/my-posts.vue?macro=true";
import { default as squareQjZb65ljQ4Meta } from "/application/pages/room/square.vue?macro=true";
import { default as licenseUserSjYMv2GWjyMeta } from "/application/pages/settings/licenseUser.vue?macro=true";
import { default as whaleMEC8wx3WslMeta } from "/application/pages/whale.vue?macro=true";
import { default as whaleLoginQct6iZjLbMMeta } from "/application/pages/whaleLogin.vue?macro=true";
export default [
  {
    name: contactSZankz4qRuMeta?.name ?? "contact",
    path: contactSZankz4qRuMeta?.path ?? "/contact",
    meta: contactSZankz4qRuMeta || {},
    alias: contactSZankz4qRuMeta?.alias || [],
    redirect: contactSZankz4qRuMeta?.redirect,
    component: () => import("/application/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: forgotNYVEfupgVwMeta?.name ?? "forgot",
    path: forgotNYVEfupgVwMeta?.path ?? "/forgot",
    meta: forgotNYVEfupgVwMeta || {},
    alias: forgotNYVEfupgVwMeta?.alias || [],
    redirect: forgotNYVEfupgVwMeta?.redirect,
    component: () => import("/application/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: google_45guideKcM7pKHv1hMeta?.name ?? "google-guide",
    path: google_45guideKcM7pKHv1hMeta?.path ?? "/google-guide",
    meta: google_45guideKcM7pKHv1hMeta || {},
    alias: google_45guideKcM7pKHv1hMeta?.alias || [],
    redirect: google_45guideKcM7pKHv1hMeta?.redirect,
    component: () => import("/application/pages/google-guide.vue").then(m => m.default || m)
  },
  {
    name: indexUEHw9xstOJMeta?.name ?? "index",
    path: indexUEHw9xstOJMeta?.path ?? "/",
    meta: indexUEHw9xstOJMeta || {},
    alias: indexUEHw9xstOJMeta?.alias || [],
    redirect: indexUEHw9xstOJMeta?.redirect,
    component: () => import("/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteujB2l87jUlMeta?.name ?? "invite",
    path: inviteujB2l87jUlMeta?.path ?? "/invite",
    meta: inviteujB2l87jUlMeta || {},
    alias: inviteujB2l87jUlMeta?.alias || [],
    redirect: inviteujB2l87jUlMeta?.redirect,
    component: () => import("/application/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: indexPzx8h2pxPfMeta?.name ?? "join",
    path: indexPzx8h2pxPfMeta?.path ?? "/join",
    meta: indexPzx8h2pxPfMeta || {},
    alias: indexPzx8h2pxPfMeta?.alias || [],
    redirect: indexPzx8h2pxPfMeta?.redirect,
    component: () => import("/application/pages/join/index.vue").then(m => m.default || m)
  },
  {
    name: infoIEJNZx1Ni1Meta?.name ?? "join-info",
    path: infoIEJNZx1Ni1Meta?.path ?? "/join/info",
    meta: infoIEJNZx1Ni1Meta || {},
    alias: infoIEJNZx1Ni1Meta?.alias || [],
    redirect: infoIEJNZx1Ni1Meta?.redirect,
    component: () => import("/application/pages/join/info.vue").then(m => m.default || m)
  },
  {
    name: loginFLbOmRceC3Meta?.name ?? "login",
    path: loginFLbOmRceC3Meta?.path ?? "/login",
    meta: loginFLbOmRceC3Meta || {},
    alias: loginFLbOmRceC3Meta?.alias || [],
    redirect: loginFLbOmRceC3Meta?.redirect,
    component: () => import("/application/pages/login.vue").then(m => m.default || m)
  },
  {
    name: profilesAwopaWV8mMeta?.name ?? "mypage-profile",
    path: profilesAwopaWV8mMeta?.path ?? "/mypage/profile",
    meta: profilesAwopaWV8mMeta || {},
    alias: profilesAwopaWV8mMeta?.alias || [],
    redirect: profilesAwopaWV8mMeta?.redirect,
    component: () => import("/application/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: securityC3ZG62rnXXMeta?.name ?? "mypage-security",
    path: securityC3ZG62rnXXMeta?.path ?? "/mypage/security",
    meta: securityC3ZG62rnXXMeta || {},
    alias: securityC3ZG62rnXXMeta?.alias || [],
    redirect: securityC3ZG62rnXXMeta?.redirect,
    component: () => import("/application/pages/mypage/security.vue").then(m => m.default || m)
  },
  {
    name: resetVnlIHvLZrkMeta?.name ?? "reset",
    path: resetVnlIHvLZrkMeta?.path ?? "/reset",
    meta: resetVnlIHvLZrkMeta || {},
    alias: resetVnlIHvLZrkMeta?.alias || [],
    redirect: resetVnlIHvLZrkMeta?.redirect,
    component: () => import("/application/pages/reset.vue").then(m => m.default || m),
    children: [
  {
    name: passwordkrcfRUa14kMeta?.name ?? "reset-password",
    path: passwordkrcfRUa14kMeta?.path ?? "password",
    meta: passwordkrcfRUa14kMeta || {},
    alias: passwordkrcfRUa14kMeta?.alias || [],
    redirect: passwordkrcfRUa14kMeta?.redirect,
    component: () => import("/application/pages/reset/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: activity_32copypmYcgM6EJ0Meta?.name ?? "room-roomId-activity copy",
    path: activity_32copypmYcgM6EJ0Meta?.path ?? "/room/:roomId()/activity%20copy",
    meta: activity_32copypmYcgM6EJ0Meta || {},
    alias: activity_32copypmYcgM6EJ0Meta?.alias || [],
    redirect: activity_32copypmYcgM6EJ0Meta?.redirect,
    component: () => import("/application/pages/room/[roomId]/activity copy.vue").then(m => m.default || m)
  },
  {
    name: activityw3kA9LDYiUMeta?.name ?? "room-roomId-activity",
    path: activityw3kA9LDYiUMeta?.path ?? "/room/:roomId()/activity",
    meta: activityw3kA9LDYiUMeta || {},
    alias: activityw3kA9LDYiUMeta?.alias || [],
    redirect: activityw3kA9LDYiUMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/activity.vue").then(m => m.default || m)
  },
  {
    name: analyticsc3VpecLb7lMeta?.name ?? "room-roomId-analytics",
    path: analyticsc3VpecLb7lMeta?.path ?? "/room/:roomId()/analytics",
    meta: analyticsc3VpecLb7lMeta || {},
    alias: analyticsc3VpecLb7lMeta?.alias || [],
    redirect: analyticsc3VpecLb7lMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/analytics.vue").then(m => m.default || m)
  },
  {
    name: formSlb1H5MPKDMeta?.name ?? "room-roomId-feedback-form",
    path: formSlb1H5MPKDMeta?.path ?? "/room/:roomId()/feedback/form",
    meta: formSlb1H5MPKDMeta || {},
    alias: formSlb1H5MPKDMeta?.alias || [],
    redirect: formSlb1H5MPKDMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/feedback/form.vue").then(m => m.default || m)
  },
  {
    name: formXLIMKI4ptUMeta?.name ?? "room-roomId-form",
    path: formXLIMKI4ptUMeta?.path ?? "/room/:roomId()/form",
    meta: formXLIMKI4ptUMeta || {},
    alias: formXLIMKI4ptUMeta?.alias || [],
    redirect: formXLIMKI4ptUMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/form.vue").then(m => m.default || m)
  },
  {
    name: index80ZMTecpudMeta?.name ?? "room-roomId",
    path: index80ZMTecpudMeta?.path ?? "/room/:roomId()",
    meta: index80ZMTecpudMeta || {},
    alias: index80ZMTecpudMeta?.alias || [],
    redirect: index80ZMTecpudMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvGVZ6JrwbMeta?.name ?? "room-roomId-member",
    path: indexvvGVZ6JrwbMeta?.path ?? "/room/:roomId()/member",
    meta: indexvvGVZ6JrwbMeta || {},
    alias: indexvvGVZ6JrwbMeta?.alias || [],
    redirect: indexvvGVZ6JrwbMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/member/index.vue").then(m => m.default || m)
  },
  {
    name: post7Uq0CIn8ByMeta?.name ?? "room-roomId-member-post",
    path: post7Uq0CIn8ByMeta?.path ?? "/room/:roomId()/member/post",
    meta: post7Uq0CIn8ByMeta || {},
    alias: post7Uq0CIn8ByMeta?.alias || [],
    redirect: post7Uq0CIn8ByMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/member/post.vue").then(m => m.default || m)
  },
  {
    name: ownPPwPMZbZNpMeta?.name ?? "room-roomId-own",
    path: ownPPwPMZbZNpMeta?.path ?? "/room/:roomId()/own",
    meta: ownPPwPMZbZNpMeta || {},
    alias: ownPPwPMZbZNpMeta?.alias || [],
    redirect: ownPPwPMZbZNpMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/own.vue").then(m => m.default || m)
  },
  {
    name: formSfqgg7fQHqMeta?.name ?? "room-roomId-post-form",
    path: formSfqgg7fQHqMeta?.path ?? "/room/:roomId()/post/form",
    meta: formSfqgg7fQHqMeta || {},
    alias: formSfqgg7fQHqMeta?.alias || [],
    redirect: formSfqgg7fQHqMeta?.redirect,
    component: () => import("/application/pages/room/[roomId]/post/form.vue").then(m => m.default || m)
  },
  {
    name: formla8DieUSWPMeta?.name ?? "room-form",
    path: formla8DieUSWPMeta?.path ?? "/room/form",
    meta: formla8DieUSWPMeta || {},
    alias: formla8DieUSWPMeta?.alias || [],
    redirect: formla8DieUSWPMeta?.redirect,
    component: () => import("/application/pages/room/form.vue").then(m => m.default || m)
  },
  {
    name: indexNXzKTGUbVGMeta?.name ?? "room",
    path: indexNXzKTGUbVGMeta?.path ?? "/room",
    meta: indexNXzKTGUbVGMeta || {},
    alias: indexNXzKTGUbVGMeta?.alias || [],
    redirect: indexNXzKTGUbVGMeta?.redirect,
    component: () => import("/application/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: my_45postsPPALfnHMtVMeta?.name ?? "room-my-posts",
    path: my_45postsPPALfnHMtVMeta?.path ?? "/room/my-posts",
    meta: my_45postsPPALfnHMtVMeta || {},
    alias: my_45postsPPALfnHMtVMeta?.alias || [],
    redirect: my_45postsPPALfnHMtVMeta?.redirect,
    component: () => import("/application/pages/room/my-posts.vue").then(m => m.default || m)
  },
  {
    name: squareQjZb65ljQ4Meta?.name ?? "room-square",
    path: squareQjZb65ljQ4Meta?.path ?? "/room/square",
    meta: squareQjZb65ljQ4Meta || {},
    alias: squareQjZb65ljQ4Meta?.alias || [],
    redirect: squareQjZb65ljQ4Meta?.redirect,
    component: () => import("/application/pages/room/square.vue").then(m => m.default || m)
  },
  {
    name: licenseUserSjYMv2GWjyMeta?.name ?? "settings-licenseUser",
    path: licenseUserSjYMv2GWjyMeta?.path ?? "/settings/licenseUser",
    meta: licenseUserSjYMv2GWjyMeta || {},
    alias: licenseUserSjYMv2GWjyMeta?.alias || [],
    redirect: licenseUserSjYMv2GWjyMeta?.redirect,
    component: () => import("/application/pages/settings/licenseUser.vue").then(m => m.default || m)
  },
  {
    name: whaleMEC8wx3WslMeta?.name ?? "whale",
    path: whaleMEC8wx3WslMeta?.path ?? "/whale",
    meta: whaleMEC8wx3WslMeta || {},
    alias: whaleMEC8wx3WslMeta?.alias || [],
    redirect: whaleMEC8wx3WslMeta?.redirect,
    component: () => import("/application/pages/whale.vue").then(m => m.default || m)
  },
  {
    name: whaleLoginQct6iZjLbMMeta?.name ?? "whaleLogin",
    path: whaleLoginQct6iZjLbMMeta?.path ?? "/whaleLogin",
    meta: whaleLoginQct6iZjLbMMeta || {},
    alias: whaleLoginQct6iZjLbMMeta?.alias || [],
    redirect: whaleLoginQct6iZjLbMMeta?.redirect,
    component: () => import("/application/pages/whaleLogin.vue").then(m => m.default || m)
  }
]